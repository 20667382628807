import { frame, cancelFrame, frameData } from '../../../frameloop/frame.mjs';

const frameloopDriver = (update) => {
    const passTimestamp = ({ timestamp }) => update(timestamp);
    return {
        start: () => frame.update(passTimestamp, true),
        stop: () => cancelFrame(passTimestamp),
        /**
         * If we're processing this frame we can use the
         * framelocked timestamp to keep things in sync.
         */
        now: () => frameData.isProcessing ? frameData.timestamp : performance.now(),
    };
};

export { frameloopDriver };
