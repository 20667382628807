export const etherUnits = {
  gwei: 9,
  wei: 18,
}
export const gweiUnits = {
  ether: -9,
  wei: 9,
}
export const weiUnits = {
  ether: -18,
  gwei: -9,
}
